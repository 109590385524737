import { Injectable, Injector } from '@angular/core';
import { FilesClient } from '@cloudmed/file-shed-angular-client';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { GLOBAL_TOASTER_KEY } from 'src/app/shared/models';
import {
  openOrDownloadFileInNewBrowserTab,
  openOrDownloadFileInBrowserTab,
} from 'src/app/shared/utils/global.utils';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(
    private readonly filesClient: FilesClient,
    private readonly messageService: MessageService,
    private readonly injector: Injector
  ) {}

  previewFile(
    fileId: string,
    healthSystemId: string,
    accountNumber: string
  ): void {
    this.filesClient
      .downloadFile(fileId, healthSystemId, accountNumber)
      .pipe(
        catchError((error) => {
          this.messageService.add({
            key: GLOBAL_TOASTER_KEY,
            severity: 'error',
            summary: `Cannot download file: ${error}`,
          });
          return throwError(() => {
            return error;
          });
        })
      )
      .subscribe((response) => {
        openOrDownloadFileInNewBrowserTab(
          response.data,
          this.injector,
          response.fileName
        );
      });
  }

  previewFileById(fileId: string): void {
    this.filesClient
      .downloadFileById(fileId, null, null)
      .pipe(
        catchError((error) => {
          this.messageService.add({
            key: GLOBAL_TOASTER_KEY,
            severity: 'error',
            summary: `Cannot download file: ${error}`,
          });
          return throwError(() => {
            return error;
          });
        })
      )
      .subscribe((response) => {
        openOrDownloadFileInBrowserTab(
          response.data,
          this.injector,
          response.fileName
        );
      });
  }
}
